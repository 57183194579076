import React from 'react';
import { graphql } from 'gatsby';

import { results } from './summer-contest-closed.module.scss';

import { IPage } from '../models/page.model';
import { IFlavour } from '../models/flavour';

import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getNodes } from '../utils/get-nodes';
import { getFlavourResults } from '../utils/get-flavour-results';

import ContestResults from '../components/organisms/contest-results';
import SummerContestLayout from '../layouts/summer-contest-layout';

interface ISummerContestPage {
    readonly data: {
        page: IPage | null;
        flavours: {
            edges: {
                node: IFlavour;
            }[];
        };
    };
}

const SummerContestPage: React.FC<ISummerContestPage> = ({ data }) => {
    const { page, flavours } = data;

    const queryResults = '1:27,2:18,3:6,4:9,5:15,6:18,7:7';
    const resultFlavours = getFlavourResults(getNodes(flavours), queryResults);
    const { t } = useI18next();

    return (
        <SummerContestLayout
            page={page}
            headerProps={{
                titleFirstLine: t('summer.contest.closed.header.1'),
                titleSecondLine: t('summer.contest.closed.header.2'),
                children: t('summer.contest.closed.header.3'),
            }}
        >
            <ContestResults
                titleFirstLine={t('summer.contest.closed.results.1')}
                titleSecondLine={t('summer.contest.closed.results.2')}
                className={results}
                hasPodium={true}
                flavourResults={resultFlavours}
            />
        </SummerContestLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        flavours: allStrapiSummerFlavour(filter: { locale: { eq: $language } }) {
            edges {
                node {
                    name
                    value
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, quality: 99, width: 125)
                            }
                        }
                        alternativeText
                    }
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default SummerContestPage;
